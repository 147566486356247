// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../fonts/SourceSansPro-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../fonts/SourceSansPro-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../fonts/SourceSansPro-BoldIt.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../fonts/SourceSansPro-BoldIt.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../../fonts/SourceSansPro-Black.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../../fonts/SourceSansPro-Black.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../../fonts/SourceSansPro-ExtraLightIt.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("../../fonts/SourceSansPro-ExtraLightIt.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("../../fonts/SourceSansPro-BlackIt.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("../../fonts/SourceSansPro-BlackIt.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_10___ = new URL("../../fonts/SourceSansPro-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_11___ = new URL("../../fonts/SourceSansPro-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_12___ = new URL("../../fonts/SourceSansPro-SemiboldIt.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_13___ = new URL("../../fonts/SourceSansPro-SemiboldIt.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_14___ = new URL("../../fonts/SourceSansPro-LightIt.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_15___ = new URL("../../fonts/SourceSansPro-LightIt.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_16___ = new URL("../../fonts/SourceSansPro-Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_17___ = new URL("../../fonts/SourceSansPro-Light.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_18___ = new URL("../../fonts/SourceSansPro-It.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_19___ = new URL("../../fonts/SourceSansPro-It.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_20___ = new URL("../../fonts/SourceSansPro-ExtraLight.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_21___ = new URL("../../fonts/SourceSansPro-ExtraLight.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_22___ = new URL("../../fonts/SourceSansPro-Semibold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_23___ = new URL("../../fonts/SourceSansPro-Semibold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_24___ = new URL("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAADFJREFUOE9jZGBgEGHAD97gk2YcNYBhmIQBgWSAP52AwoAQwJvQRg1gACckQoC2gQgAIF8IscwEtKYAAAAASUVORK5CYII=", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_25___ = new URL("@/assets/svg/Icon_time.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_26___ = new URL("@/assets/svg/Icon_search.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
var ___CSS_LOADER_URL_REPLACEMENT_12___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_12___);
var ___CSS_LOADER_URL_REPLACEMENT_13___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_13___);
var ___CSS_LOADER_URL_REPLACEMENT_14___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_14___);
var ___CSS_LOADER_URL_REPLACEMENT_15___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_15___);
var ___CSS_LOADER_URL_REPLACEMENT_16___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_16___);
var ___CSS_LOADER_URL_REPLACEMENT_17___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_17___);
var ___CSS_LOADER_URL_REPLACEMENT_18___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_18___);
var ___CSS_LOADER_URL_REPLACEMENT_19___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_19___);
var ___CSS_LOADER_URL_REPLACEMENT_20___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_20___);
var ___CSS_LOADER_URL_REPLACEMENT_21___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_21___);
var ___CSS_LOADER_URL_REPLACEMENT_22___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_22___);
var ___CSS_LOADER_URL_REPLACEMENT_23___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_23___);
var ___CSS_LOADER_URL_REPLACEMENT_24___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_24___);
var ___CSS_LOADER_URL_REPLACEMENT_25___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_25___);
var ___CSS_LOADER_URL_REPLACEMENT_26___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_26___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/*

███████╗██╗ ██████╗ ███╗   ██╗ █████╗  ██████╗ ███████╗
██╔════╝██║██╔════╝ ████╗  ██║██╔══██╗██╔════╝ ██╔════╝
███████╗██║██║  ███╗██╔██╗ ██║███████║██║  ███╗█████╗  
╚════██║██║██║   ██║██║╚██╗██║██╔══██║██║   ██║██╔══╝  
███████║██║╚██████╔╝██║ ╚████║██║  ██║╚██████╔╝███████╗
╚══════╝╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚═╝  ╚═╝ ╚═════╝ ╚══════╝

*/
.multiselect {
  width: 200px;
  min-height: unset;
}
.multiselect .multiselect__select {
  height: 40px !important;
}
.multiselect .multiselect__tags {
  width: 100%;
  min-width: unset;
  display: flex;
  align-items: center;
  height: 40px;
  min-height: unset;
  padding-top: 0;
  justify-content: flex-start;
  padding-left: 12px;
}
.multiselect .multiselect__tags .multiselect__single,
.multiselect .multiselect__tags .multiselect__placeholder,
.multiselect .multiselect__tags .multiselect__input {
  background: transparent;
  margin: 0;
  padding: 0;
}

span.multiselect__placeholder {
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #b5b5b5;
}

.multiselect__tags {
  border: solid 2px #ededed !important;
  padding: 8px 40px 8px 8px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.multiselect__single {
  margin-bottom: 0 !important;
}

.multiselect__input {
  background: none !important;
}

.multiselect .multiselect__content-wrapper {
  min-width: 100% !important;
  width: auto;
  border: none;
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1);
}

.multiselect__single {
  white-space: nowrap;
  overflow: hidden;
}

.multiselect--active .multiselect__tags {
  border-bottom: none;
}

.multiselect--active input, .multiselect__placeholder {
  margin-bottom: unset !important;
  padding-top: unset !important;
  font-size: 0.86rem !important;
}

.select-composer .multiselect__content-wrapper {
  min-width: calc(100% + 70px) !important;
}

.select-composer .multiselect__tags, .select-composer .multiselect__select, .select-composer .multiselect {
  height: 35px !important;
  min-height: 35px !important;
}

.select-composer .multiselect__tags {
  border: solid 2px #dbdbdb !important;
}

.multiselect__strong {
  margin-bottom: 0 !important;
  margin-right: 5px;
}

.custom__tag {
  display: inline-block;
  padding: 0 12px;
  background-color: #484848;
  color: white;
  margin-right: 8px;
  border-radius: 10px;
  cursor: pointer;
}

.multiselect__option--highlight {
  background: #ededed !important;
  color: unset !important;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: unset !important;
  color: unset !important;
}

.multiselect__option--selected {
  background: #f4F4F4 !important;
}

span.multiselect__option--highlight.multiselect__option--selected.multiselect__option {
  background: #ededed !important;
}

/* === FONTS === */
@font-face {
  font-family: "Source Sans Pro";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_9___}) format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_10___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_11___}) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_12___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_13___}) format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_14___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_15___}) format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_16___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_17___}) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_18___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_19___}) format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_20___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_21___}) format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_22___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_23___}) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
/* === Calendar Styles */
.left-calendar .vc-day.is-today .vc-highlight {
  border-radius: 5px !important;
  background-color: rgba(98, 140, 189, 0.2) !important;
  border: 0.82px solid #4195ff !important;
}

.left-calendar .vc-weekday {
  font-size: 10.7px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #9c9d9f;
}

.left-calendar .vc-title {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #484848;
}

.left-calendar .vc-day.in-month:not(.is-today) .vc-highlight, .left-calendar .vc-day.in-month .vc-day-layer:nth-child(2) .vc-highlight {
  border-radius: 5px !important;
  background-color: #484848 !important;
  border: 2px solid #484848 !important;
}

.left-calendar .vc-day.in-month.is-today span.vc-day-content[tabindex="0"] {
  color: white !important;
  font-weight: 500 !important;
}

.left-calendar .vc-highlight-content-light {
  color: #4195ff !important;
  font-weight: 500 !important;
}

.left-calendar *:focus {
  background: unset !important;
}

.left-calendar *:focus, .left-calendar *:hover {
  border-radius: 5px !important;
}

.left-calendar .vc-arrow {
  border-radius: 6.6px !important;
  border: solid 2px #ddd !important;
  background-color: #fff;
  width: 26.4px !important;
  height: 26.4px !important;
}

.left-calendar .vc-arrow .vc-base-icon {
  transform: scale(0.6);
  stroke-width: 4px;
  color: black;
}

.left-calendar .vc-day-content {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 1px;
}

.left-calendar .vc-highlights {
  scale: 1.1;
}

.fc-event {
  cursor: pointer;
}

.fc-daygrid-event {
  border: 2px solid;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin-left: 2px;
}

.fc {
  /* makes calendar take up all available with (needed for sidebar integration) */
  width: 100% !important;
  /* fixes calendar with on sidebar open/close */
  /* additional fix for week mode */
}
.fc .fc-col-header,
.fc .fc-daygrid-body,
.fc .fc-scrollgrid-sync-table {
  inline-size: 100% !important;
}
.fc .fc-col-header,
.fc .fc-daygrid-body,
.fc .fc-scrollgrid-sync-table,
.fc .fc-timegrid-body,
.fc .fc-timegrid-body table {
  width: 100% !important;
}

td.fc-timegrid-slot.fc-timegrid-slot-lane {
  background-color: white;
  border-bottom: 1px solid rgba(219, 219, 219, 0.5) !important;
}

.fc-timeGridDay-view table.fc-scrollgrid, .fc-timeGridDay-view table.fc-scrollgrid * {
  border: 0;
}

.fc-timeGridDay-view table a.fc-event.fc-timegrid-event.fc-v-event {
  border: 2px solid;
}

.fc-timeGridDay-view th.fc-col-header-cell.fc-day {
  background: #F4f4f4 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #484848 !important;
  text-transform: capitalize !important;
}

.fc-listWeek-view .fc-list-event-title {
  padding: 0 !important;
}

.fc .fc-list-day-cushion, .fc .fc-list-table td {
  padding: 6px 14px;
}

button.fc-button.fc-button-primary {
  border: 0;
  outline: 0;
}

.fc .fc-toolbar {
  align-items: stretch;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin: 0;
  height: 45px;
}

.fc .fc-daygrid-day-top {
  flex-direction: row;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
  margin: 5px 0px 5px 3px;
  line-height: 0.9;
}

.fc-list-day-cushion a {
  font-weight: 600;
}

th.fc-col-header-cell.fc-day {
  color: #969696;
  text-align: left;
  height: 40px;
  padding: 5px;
  font-weight: 600;
  text-transform: uppercase;
}

.fc .fc-scrollgrid {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.fc .fc-scrollgrid th {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
  background-color: #4195ff;
  place-content: center;
  color: #fff;
  border-radius: 5px;
  height: 26px;
  width: 26px;
  align-items: center;
  display: flex;
  text-align: center;
}
.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number.fc-daygrid-more-link {
  color: #4195ff !important;
  background-color: #f0f0f0 !important;
  font-weight: 600;
}

.fc .fc-daygrid-more-link:hover {
  color: #4195ff;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #f0f0f0;
}

.fc-view-harness.fc-view-harness-active .fc-list {
  border: 0 !important;
}

.fc-timegrid.fc-timeGridDay-view th .fc-scroller-harness .fc-scroller {
  overflow: hidden hidden !important;
}

.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: none;
}

button.fc-button {
  height: 25px !important;
  background: #fff !important;
  color: #8d8d8d !important;
  text-transform: capitalize;
  padding: 7px 16px 5px 15px !important;
  border-radius: 5px !important;
  border: 0 !important;
}

button.fc-button.fc-button-active {
  background: #484848 !important;
  color: #fff !important;
}

th.fc-day.fc-day-today {
  background: #484848;
  color: white;
}

button.fc-prev-button, button.fc-next-button {
  height: 30px;
  width: 50px;
  padding: 1px !important;
}

.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  min-height: 100px !important;
}

.fc-dayGridMonth-view .fc-daygrid-day-frame {
  height: 100px;
}

.fc .fc-daygrid-day-bottom {
  margin-top: -25px !important;
  float: right;
  top: 0;
  right: 0;
  position: absolute;
}

.theme-picker {
  position: relative;
  width: auto;
  gap: 0.5rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  min-width: 67px;
  height: 35px;
  border-radius: 5px;
  border: solid 2px #dbdbdb;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition-duration: 0.4s;
}
.theme-picker.active, .theme-picker:hover {
  background: #dbdbdb;
}
.theme-picker .square-select {
  width: 30px;
  height: 20px;
  border: 0.1px solid rgba(211, 211, 211, 0.5);
  border-radius: 3px;
  background-blend-mode: lighten;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_24___});
  background-position: 0 0, 10px 10px;
  background-size: 6px;
}
.theme-picker .theme-config {
  border-radius: 5px;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  width: max-content;
  height: auto;
  position: absolute;
  left: 0;
  top: 36px;
  z-index: 1;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
.theme-picker .theme-config .text {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}
.theme-picker .theme-config .config-list {
  margin-top: 5px;
  max-height: 370px;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.theme-picker .theme-config .config-list span {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  margin-left: 30px;
}
.theme-picker .theme-config .config-list::-webkit-scrollbar {
  display: none;
}
.theme-picker .theme-config .config-item {
  padding: 7px 0;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: left;
  display: flex;
  color: #484848;
  gap: 2px;
}
.theme-picker .theme-config .config-item .actions, .theme-picker .theme-config .config-item .delete {
  width: 30px;
  height: 20px;
  display: flex;
  justify-content: center;
}
.theme-picker .theme-config .config-item .delete svg {
  display: none;
}
.theme-picker .theme-config .config-item:hover .delete svg {
  display: block;
}
.theme-picker .theme-config .config-item .square {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 0.1px solid rgba(211, 211, 211, 0.5);
  background-blend-mode: lighten;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_24___});
  background-position: 0 0, 10px 10px;
  background-size: 6px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.theme-picker .theme-config .config-item:hover, .theme-picker .theme-config .config-item.active {
  background-color: #ededed;
}
.theme-picker .new-theme {
  border-radius: 5px;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.12);
  background: #f4f4f4;
  width: max-content;
  height: auto;
  display: flex;
  position: absolute;
  left: 0;
  top: 36px;
  z-index: 1;
  padding: 7px;
}
.theme-picker .new-theme span {
  margin-right: 7px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #484848;
}
.theme-picker .new-theme .theme-item {
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 0.15em;
}
.theme-picker .new-theme .square {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 0.1px solid rgba(211, 211, 211, 0.5);
  background-blend-mode: lighten;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_24___});
  background-position: 0 0, 10px 10px;
  background-size: 6px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.theme-picker .square-divider {
  width: 2px;
  height: 20px;
  margin-inline: 4px;
  background-color: #dbdbdb;
}

.Content-composer p {
  margin: 0;
}

.container-vertical {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  justify-content: flex-start;
  row-gap: 5px;
  margin: 5px;
}
.container-vertical.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.toolbar-button {
  background-color: transparent;
  color: black;
  border: 2px solid transparent;
  padding: 5px 12px 5px 12px;
  border-radius: 5px;
  width: 35px;
  height: 35px;
  transition-duration: 0.4s;
  cursor: pointer;
  display: flex;
  align-items: center;
  place-content: center;
}
.toolbar-button.active {
  background: white;
}

.toolbar-button:hover {
  background-color: white;
}

.header-button, .header-button.disabled {
  min-width: 42px;
  width: auto;
  height: 35px;
  flex-grow: 0;
  display: flex;
  align-items: center;
  padding: 8px 8px 8px 8px;
  border-radius: 3.2px;
  border: 2px solid transparent;
  background-color: #fff;
  place-content: center;
}
.header-button.disabled {
  background: #f4f4f4;
  pointer-events: none;
}
.header-button.disabled img {
  filter: brightness(2);
}

.header-button:hover {
  border-color: #4195ff;
}

.composer-label {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #484848;
}

.inputTime {
  width: 60px;
  height: 35px;
  border-radius: 5px;
  border: solid 2px #dbdbdb;
  align-items: center;
  text-align: left;
  padding-left: 10px;
}

.hide-arrows {
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}
.hide-arrows input::-webkit-outer-spin-button,
.hide-arrows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.hide-arrows input[type=number] {
  -moz-appearance: textfield;
}

.font-size-picker {
  /* Dropdown Content (Hidden by Default) */
  /* Links inside the dropdown */
  /* Change color of dropdown links on hover */
}
.font-size-picker .dropdown-content {
  margin-top: 3px;
  display: none;
  position: absolute;
  background-color: white;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
  height: 396px;
  overflow: auto;
  margin-left: -30px;
  width: 100px;
}
.font-size-picker .dropdown-value {
  font-size: 18px;
  font-weight: 600;
  color: #484848;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.font-size-picker .dropdown-value:hover {
  background-color: #ddd;
}
.font-size-picker .font-size {
  width: 40px;
  height: 35px;
  font-size: 18px;
  background-color: #fff;
  border: none;
  text-align: center;
}
.font-size-picker .font-size:focus {
  outline: none;
}

.Vue-Toastification__container {
  z-index: 2147483647 !important;
}

.Vue-Toastification__toast {
  padding: 25.5px 24px !important;
}
.Vue-Toastification__toast .Vue-Toastification__toast-body {
  font-size: 16px;
  font-family: "Source Sans Pro";
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8em;
}
.Vue-Toastification__toast .Vue-Toastification__icon {
  width: 28.8px !important;
}

.Vue-Toastification__toast--success {
  background-image: linear-gradient(to bottom, #8acd52 0%, #55be01 100%);
}
.Vue-Toastification__toast--success .Vue-Toastification__progress-bar {
  background-color: #55be01;
}

.Vue-Toastification__toast--error {
  background-image: linear-gradient(to bottom, #f99898 0%, #ff6d6d 100%);
}
.Vue-Toastification__toast--error .Vue-Toastification__progress-bar {
  background-color: #ff6d6d;
}

.Vue-Toastification__progress-bar {
  top: 0;
}

html {
  overflow: hidden;
}

html, html body {
  font-family: "Source Sans Pro" !important;
  min-height: 100%;
  background: #f4F4F4;
}

body select option {
  font-family: inherit !important;
}

body:has(.modal) {
  overflow: hidden;
}

.bg-1 {
  background-color: #ffffff !important;
}

.bg-2 {
  background-color: #f5f5f5 !important;
}

.bg-3 {
  background-color: #464646 !important;
}

.bold {
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
}

.bolder {
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
}

.avatar {
  vertical-align: middle;
  min-width: 40px;
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.unselectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.dialog-label {
  width: 100%;
  height: 18px;
  margin-bottom: 19px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
  color: #484848;
}

.form-control {
  border-width: 2px !important;
}

.form-control:focus {
  box-shadow: unset !important;
}

.form-control:not(.is-invalid) {
  border-radius: 5px !important;
  border: solid 2px #ededed;
}

.form-control::placeholder {
  color: #b5b5b5 !important;
}

input::placeholder {
  color: #b5b5b5;
}

.vc-popover-content-wrapper {
  z-index: 5000 !important;
}

.vc-popover-content-wrapper select {
  border: 0;
}

.form-label {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.5px;
  text-align: left;
  color: #8d8d8d;
  margin-bottom: 0 !important;
  margin-left: 15px;
}
.form-label.no-m {
  margin-left: 0;
}

#image_overlay_panel {
  z-index: 99999999 !important;
}

.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10000;
  top: 0;
  left: 0;
  background-color: rgba(72, 72, 72, 0.1411764706);
}

.sideMenuBar {
  width: 300px !important;
  overflow-y: auto;
  border-right: 1px solid rgb(243, 243, 243);
  background: #f5f5f5;
}

.subContent {
  margin-left: 283px;
}

.vc-day .vc-day-content.vc-focusable.vc-focus.vc-attr.vc-attr.vc-highlight-content-light.vc-gray[tabindex="0"] {
  color: white !important;
}

/* .sideMenuBar span.vc-day-content[tabindex="0"] {
  border-radius: 5px;
  background-color: #484848 !important;
  color: white !important;
} */
.theme-select-box {
  position: absolute;
  z-index: 1000;
  right: 0;
  top: 50px;
  width: 220px;
  height: auto;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.12);
  padding: 5px 0;
  font-size: 14px;
  color: #484848;
  font-weight: 600;
}
.theme-select-box .theme-select-item {
  width: 100%;
  padding: 6px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 11px;
  text-align: -webkit-center;
}
.theme-select-box .theme-select-item svg {
  display: flex;
}
.theme-select-box .theme-select-item:hover {
  background-color: #EDEDED;
}
.theme-select-box .theme-select-svg {
  width: 20px;
}
.theme-select-box .theme-select-divider {
  width: 100%;
  height: 2px;
  margin: 4px 0;
  background-color: #dbdbdb;
}

.theme-tab {
  width: 147px;
  height: 43px;
  border-radius: 5px;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  color: #b5b5b5;
  font-weight: 600;
  background-color: #f4f4f4;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.theme-tab.inverted {
  text-transform: uppercase;
  background-color: #ffffff !important;
}
.theme-tab.inverted.active {
  background-color: #f4f4f4 !important;
  border-color: #ffffff;
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.1);
}
.theme-tab:hover {
  cursor: pointer;
}
.theme-tab.active {
  height: 49px;
  border: 8px solid #f4f4f4;
  border-bottom: 0;
  color: #464646;
  background-color: #fff;
  border-radius: 5px;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}

.theme-button, .theme-button-icon, .theme-button-sm, .theme-button-sm-icon {
  background-color: #ffffff;
  color: black;
  border: 2px solid #e0e0e0;
  padding: 5px 12px 5px 10px;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  height: 40px;
  width: max-content;
  transition-duration: 0.4s;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: 0.42px;
}
.theme-button.disabled *, .disabled.theme-button-icon *, .disabled.theme-button-sm *, .disabled.theme-button-sm-icon *, .theme-button.disabled, .disabled.theme-button-icon, .disabled.theme-button-sm, .disabled.theme-button-sm-icon {
  color: #dbdbdb;
  stroke: #dbdbdb;
  pointer-events: none;
}
.theme-button-sm, .theme-button-sm-icon {
  height: 35px;
  padding: 2px 12px 2px 10px !important;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.5px;
  text-align: left;
  color: #484848;
}
.theme-button-sm-icon {
  display: flex;
  align-self: baseline;
  align-items: center;
  gap: 5px;
}
.theme-button-sm-icon svg, .theme-button-sm-icon img {
  max-width: 20px;
  max-height: 20px;
}
.theme-button-icon {
  display: flex;
  align-self: baseline;
  align-items: center;
  gap: 5px;
}
.theme-button-icon svg, .theme-button-icon img {
  max-width: 20px;
  max-height: 20px;
}

.theme-button2 {
  background-color: #ffffff;
  color: #b5b5b5;
  height: 35px;
  border: 2px solid transparent;
  padding: 9px 29px 10px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  width: max-content;
  font-weight: 500;
  transition-duration: 0.4s;
  cursor: pointer;
}

.theme-navbutton {
  border: 2px solid #dbdbdb;
  background-color: #ffffff;
  width: 35px;
  height: 35px;
  display: flex;
  flex-grow: 0;
  align-items: center;
  padding: revert;
  border-radius: 6px;
}

.theme-button:hover, .theme-button-sm:hover, .theme-button-sm-icon:hover, .theme-button-icon:hover, .theme-button2:hover, .theme-navbutton:hover {
  background-color: #e0e0e0;
}

.theme-button.active, .active.theme-button-sm, .active.theme-button-sm-icon, .active.theme-button-icon {
  border-color: #4195ff;
}

.theme-button2.active {
  color: white;
  background-color: #484848;
}

.btn-bg-conf {
  background: #4195ff !important;
  border-color: #4195ff !important;
  color: white;
}
.btn-bg-conf:hover {
  opacity: 0.8;
}

.btn-bg-red {
  background: #ff6d6d !important;
  border-color: #ff6d6d !important;
  color: white;
}

.color-bucket {
  position: relative;
  min-width: 67px;
  width: 67px;
  height: 35px;
  border-radius: 5px;
  border: solid 2px #dbdbdb;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition-duration: 0.4s;
}
.color-bucket.active, .color-bucket:hover {
  background: #dbdbdb;
}
.color-bucket.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.drop-down {
  position: absolute;
  top: 36px;
  left: 0;
  width: 111px;
  height: auto;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.2);
}
.drop-down .option {
  height: 34px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  align-self: center;
  width: 111px;
  margin-left: 0px;
}
.drop-down .option:hover {
  background-color: #ededed;
}

.drop-down .option:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.drop-down .option:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.form-select.font-select, .font-select {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.5px;
  text-align: left;
  color: #484848;
  padding: 0 12px 0 12px;
  height: 35px;
  width: 189px;
  margin-bottom: 0;
  margin-top: 0;
  border-radius: 5px;
  border: solid 2px #dbdbdb;
}

.theme-navbutton.active {
  background: #ededed;
  border: 2px solid white;
}

.theme-font-1 {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #484848;
}

.clickable {
  cursor: pointer;
}

.date-input {
  height: 40px !important;
  width: 146px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.date-input.invalid {
  border-color: #dc3545;
}
.date-input input {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
}

.time-input {
  height: 40px !important;
  width: 146px !important;
  display: flex !important;
  flex-direction: column !important;
}
.time-input.invalid {
  border-color: #dc3545;
}
.time-input input {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
}

input[type=time]::-webkit-calendar-picker-indicator {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_25___}) no-repeat center;
  cursor: pointer;
}

input.search-input {
  outline: none;
  width: 200px;
  height: 35px;
  font-size: 14px;
  background: white url(${___CSS_LOADER_URL_REPLACEMENT_26___}) no-repeat scroll 7px 6px;
  padding-left: 30px;
  border-radius: 5px;
  border: solid 2px #dbdbdb;
  cursor: pointer;
}

input.search-input:focus {
  border: solid 2px #4195ff;
  cursor: text;
}

input.search-input:not(:focus):hover {
  background-color: #dbdbdb;
}

.confirm-input {
  display: flex;
  gap: 2px;
  min-width: 358px;
  height: 35px;
  background-color: #fff;
  border-radius: 5px;
  border: solid 2px #dbdbdb;
  padding: 0 0.2rem;
  align-items: center;
  transition: border 0.3s ease;
}
.confirm-input.bigger {
  min-width: 446px;
}
.confirm-input.invalid {
  border: 2px solid #dc3545;
}
.confirm-input .confirm-dropdown {
  position: relative;
  width: 62px;
  height: 27px;
  border-radius: 2.5px;
  background-color: #ededed;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.29;
  letter-spacing: 0.5px;
  color: #484848;
  cursor: pointer;
}
.confirm-input .confirm-dropdown .confirm-dropdown-box {
  position: absolute;
  z-index: 1000;
  top: 1.9rem;
  background: white;
  left: 0;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  font-weight: normal;
  border-radius: 5px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
}
.confirm-input .confirm-dropdown .confirm-dropdown-box .confirm-dropdown-item {
  padding: 5px 10px;
  width: 100%;
}
.confirm-input .confirm-dropdown .confirm-dropdown-box .confirm-dropdown-item.active {
  font-weight: 600;
}
.confirm-input .confirm-dropdown .confirm-dropdown-box .confirm-dropdown-item:hover, .confirm-input .confirm-dropdown .confirm-dropdown-box .confirm-dropdown-item.active {
  background: #ededed;
}
.confirm-input .confirm-icon {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-inline: 5px;
}
.confirm-input .confirm-icon span {
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: 0.5px;
  text-align: left;
  color: #484848;
  width: max-content;
}
.confirm-input .confirm-text {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.29;
  letter-spacing: 0.5px;
  text-align: left;
  color: #484848;
}
.confirm-input input {
  width: 100%;
  height: auto;
  border: none;
  outline: none;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #484848;
}
.confirm-input .confirm-actions {
  display: flex;
  align-items: center;
  gap: 5px;
}
.confirm-input .confirm-actions svg {
  cursor: pointer;
}

.divider {
  width: 2px;
  min-width: 2px;
  height: 26.5px;
  border-radius: 4px;
  background-color: #dbdbdb;
}

.divider-horizontal {
  margin: 5px 0;
  border-bottom: 1px solid #d9d9d9;
}

.el {
  margin-right: 15px;
}

.closeBtn {
  width: 16px !important;
}

.invalid-select {
  outline: 2px solid #dc3545;
  border-radius: 5px;
  outline-offset: -1px;
}

.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.disable-scrollbars::-webkit-scrollbar {
  background: transparent; /* Chrome/Safari/Webkit */
  width: 0px;
}

.custom-email {
  position: relative;
  width: auto;
}
.custom-email .custom-email-input {
  width: 100%;
  height: 40px;
  border: solid 2px #ededed;
  border-radius: 5px;
  padding: 6px 45px 6px 12px;
  box-sizing: border-box;
  outline: none;
}
.custom-email .custom-email-button {
  position: absolute;
  right: 5px;
  align-self: center;
  top: 5px;
  border: 0;
  background: #4195FF;
  width: 33px;
  height: 30px;
  color: #fff;
  outline: none;
  margin: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 5px;
  z-index: 2;
}
.custom-email .custom-email-button:hover {
  opacity: 0.8;
}

.chip {
  width: fit-content;
  font-weight: 300;
  padding-left: 5px;
  font-size: 14px;
  height: 22px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
  color: white;
  letter-spacing: 0.5px;
  border-radius: 5px;
  border: solid 2px #484848;
  background-color: #484848;
  user-select: none;
}
.chip svg {
  height: 21px;
  width: 20px;
  cursor: pointer;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
button, input[type=submit], input[type=reset] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

input {
  color: #484848;
}

input::placeholder {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #b5b5b5;
}

/* Warning Tooltip */
.form-tooltip .form-tooltiptext {
  width: 95%;
  background-color: #ededed;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 2;
  margin-left: 15px;
  border: 1.5px solid lightgray;
  box-shadow: 2px 6px 10px 1px rgba(0, 0, 0, 0.12);
}

.form-tooltip .form-tooltiptext i {
  color: orange;
  margin-right: 5px;
}

.form-tooltip .form-tooltiptext::before,
.form-tooltip .form-tooltiptext::after {
  content: "";
  position: absolute;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  top: 24%;
  left: 0;
  margin-left: -10px;
}

.form-tooltip .form-tooltiptext::before {
  border-right: 7px solid lightgray;
  margin-left: -8px;
}

.form-tooltip .form-tooltiptext::after {
  border-right: 7px solid #ededed;
  margin-left: -6.5px;
  z-index: 1;
}

.disable {
  pointer-events: none;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
